<template>
    <div>
      <TopNav />
      <div class="bottom-div">
        
        <h1 class="text-4xl">Sold Out For This Year</h1>

        <!-- <div id="countdown"></div> until the price goes back to $9.99 -->

        <div class="md:hidden my-8 p-4 rounded-lg bg-blue-100 text-blue-900 shadow-lg w-full sm:w-3/6">

          <p class="text-justify">
            <ul>
              <li><b>Includes</b></li>
              <li>- Personalized Letter from Santa</li>
              <li>- Official Nice List Certificate</li>
              <li>- North Pole Bookmark</li>
              <li>- 2 Christmas Themed Stickers</li>
              <li>- Santa's Coloring Sheet</li>
              <li>- North Pole Word Search</li>
              <li>- <i>Shipping is Included</i></li>
            </ul>

            </p>
        </div>

        <div class="hidden md:block my-8 p-4 rounded-lg bg-blue-100 text-blue-900 shadow-lg w-3/6">
          <p class="text-center">
            Every letter from Santa is a treasure trove of personal touches, tailored to delight your child with a sprinkle of North Pole magic. Crafted with care, Santa's letters transform your child's achievements and wishes into a memorable Christmas keepsake. Share a detail, create a moment.
            </p>
        </div>

        <!--check source code for next year-->
      </div>
    </div>
</template>


  
  <style>
  /* Global styles */
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  
  .top-div {
    background-image: url('../../public/northpole.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .content {
    width: 95%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .hamburger {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    z-index: 200;
  }
  
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-100vh);
    transition: transform 0.3s ease;
    z-index: 150;
  }
  
  .navbar.active {
    transform: translateY(0);
  }
  
  .navbar a {
    color: white;
    font-weight: bold;
    font-family: Verdana, sans-serif;
    font-size: 24px;
    text-decoration: none;
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .navbar a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .logo-container {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  
  .logo {
    width: 80%;
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    filter: grayscale(100%) invert(1);
  }
  
  .request-letter-btn {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 36px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  
  .request-letter-btn:hover {
    background-color: darkred;
  }
  
  img {
    width: 100%;
  }
  
  @media screen and (max-width: 599px) {
    .request-letter-btn {
      position: fixed;
      bottom: 0;
      z-index: 150;
    }
  }
  
  .bottom-div {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card {
    width: 90%;
    max-width: 400px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card img {
    width: 100%;
    border-radius: 5px;
  }
  
  .card p {
    padding-top: 10px;
    text-align: justify;
    text-justify: inter-word;
  }
  
  @media screen and (min-width: 600px) {
    .hamburger {
      display: none;
    }
  
    .navbar {
      flex-direction: row;
      justify-content: center;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      box-shadow: none;
      width: fit-content;
      height: auto;
    }
  
  
    .navbar a {
      margin: 0 20px;
    }
  
  .logo {
    width: 80%;
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    filter: grayscale(100%) invert(1);
  }
  
  .top-div {
    background-image: url('../../public/northpole.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 700px;
    position: relative;
  }
  
    .content {
      flex-direction: row;
      justify-content: space-around;
      width: 90%;
    }
  
    .card {
      width: 45%;
      margin: 20px;
    }
  }

  .hidethis{
    display: none;
  }
  
  @media screen and (min-width: 1024px) {
    .content {
      width: 80%;
      flex-direction: row;
      justify-content: space-between;
    }
    
    .nonhome-logo-container .logo {
        padding: 10px 0px ;
        width: 50%; /* This will make the logo even smaller when not on home page */
        float: right;
        max-width: 350px;
    }
  
  .request-letter-btn {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 36px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 30%;
    transition: background-color 0.3s;
  }
  
  .logo-container {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 100px 0;
  }
  
    .card {
      width: 22%;
    }
  }
  </style>
  